import React, {memo} from 'react'
import DSESignUpForm from '../components/DSE/DSESignUpForm/DSESignUpForm'
import DSELayout from "../components/DSE/DSELayout/DSELayout";
import DSEWrapper from '../components/DSE/DSEWrapper/DSEWrapper'

/* As you probably know, Gatsby makes it very easy to route the project.  You simply need to add the .js or .jsx file To the pages directory and then the name of the page becomes the route.  However, in order to organize the project Properly, you would typically put the .jsx file and the accompanying .scss file into it's own directory.  Doing That for the pages, would create a double directory for the route.  For example, /home/home.  As a result, For organization purposes, I have placed the pages here and simply returned the accompanying, 'Content' jsx. The content jsx and scss are organized as they're supposed to be, within there own directory and that is theWhere the content for each page really begins.
 */

const Signup = props => {
	return (
		<DSELayout
			canonicalURL='https://www.npcfacts.com/signup'
			title='JOIN THE NPC FACTS COMMUNITY'
			pageTitle='JOIN THE NPC FACTS COMMUNITY'
			description='The NPC Facts Community is dedicated to provide educational tools and resources to help support you on your NPC journey'
			keyWords='Nasopharyngeal carcinoma; NPC treatments; NPC outcomes'
			showCta={true}
		>
			<DSEWrapper bgClass="solid">
				<DSESignUpForm signUpForm={true} />
			</DSEWrapper>
		</DSELayout>
	)
}

export default memo(Signup)
